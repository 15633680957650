@import 'variables';
@import '../../node_modules/leaflet/dist/leaflet';
@import 'leaflet';

body {
  background-color: $red;
  margin: 0;
  font-family: sans-serif;
  font-weight: normal;
  font-size: $font-size;
  line-height: 1.3em;
}

#map {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

h1,
h2,
h3,
h4,
p,
ul,
ol,
li,
a {
  font-size: inherit;
  font-weight: normal;
  margin: 0;
  padding: 0;
}

h1,
h2,
h3 {
  text-transform: uppercase;
  margin-bottom: 1.3em;
}

$indent-info: 80px;

.info {
  position: fixed;
  top: 0;
  left: calc(100% - 380px);
  width: calc(380px - #{$space * 2});
  height: 100%;
  background-color: $red;
  box-shadow: $box-shadow;
  padding: $space;

  transition: left 300ms ease-in-out;

  p {
    transition: transform 300ms ease-in-out;
  }

  &--hidden {
    left: calc(100% - #{$indent-info});
    p {
      transform: translateX($indent-info);
    }
  }
}
