.leaflet-container {
  font-size: inherit;
  line-height: inherit;
  color: black;

  .leaflet-control-container {

  }

  .leaflet-bar {
    border: 0;
    box-shadow: $box-shadow;

    a {
      background-color: $red;
      border-radius: 0 !important;
      border: 0 !important;
      &:first-child {
        margin-bottom: 1px;
      }
    }
  }

  .leaflet-left {
    left: $space;
  }

  .div-icon {
    background-color: red;
    width: $space * 2 !important;
    height: $space * 2 !important;
    box-shadow: $box-shadow;
    border-radius: 50%;
  }

  .leaflet-popup-tip-container {
    display: none;
  }


  .leaflet-popup-content {
    margin: $space;

    p {
      font-size:inherit;
      line-height: inherit;
      margin: 0;
      color: black;
    }
  }

  .leaflet-popup-content-wrapper {
    box-shadow: $box-shadow;
    background-color: $red;
    color: black;
    border-radius: 0;
  }

  a.leaflet-popup-close-button {
    color: black;
    padding: 4px 1px;

    &:hover {
      color: black;
    }
  }
}
